<template>
	<div class="order-list" :style="!isWeixin_status ? 'padding-top: 50px':''">
		<nav-bar 
      title="消费订单" 
      fixed
      v-if="!isWeixin_status"
      @click-left="goBack"
      :left-arrow="isWeixin_status == false"
    />
    <List
      v-model="loading_status"
      :finished="more_status"
      finished-text="没有更多了"
      @load="getOrderList()"
    >
      <OilorderItem :item_arr="orderlist"> </OilorderItem>
    </List>
	</div>
</template>
<script>
import Order from "@/api/order";
import CreditShop from "@/api/creditshop"
import OilorderItem from "@components/public/oilorderItem";
import { NavBar, List, } from "vant";
export default {
  components: {
    NavBar,
    List,
    OilorderItem
  },
  data() {
    return {
      active: this.$route.query.active || 0,
      orderlist: [],
      limit: 10,
      page: 1,
      radio: '1',
      nowOrdersn: "",
      showModel: false,
      loading_status: false,
      more_status: false,
      show_status: false
    };
  },
  watch: {
    active: function() {
      this.page = 1;
      this.more_status = false;
      this.orderlist = [];
      this.getOrderList();
    }
  },
  methods: {
    async getOrderList() {
      try {
        const res = await CreditShop.oilOrderList(2, this.page++);
        if (res.code == 200) {
          if (res.data.length > 0) {
            for (let i in res.data) {
              let obj = res.data[i];
              this.orderlist.push(obj)
            }
            this.loading_status = false
             if (res.data.length == res.total) {
              this.more_status = true;
            }
          } else {
            throw('没有更多了')
          }
        } else {
          throw('暂无数据....')
        }
      } catch(err) {
        this.loading_status = false;
        this.more_status = true;
        console.log(err);
      }
    },
    // 展示退款条件
    showTianjian(ordersn) {
      this.showModel = true;
      this.nowOrdersn = ordersn;
    },
    // 取消订单
    async outOrder() {
      const res = await Order.outOderPay(this.nowOrdersn, this.personList[this.radio], "");
      if (res.code == 200) {
        this.$toast("退款成功...");
        this.showModel = false;
      } else {
        throw(res.msg);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.order-list {
	width: 100%;
	height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
	background: #f4f4f4;
}

.shopping-list {
	width: 100%;
	height: auto;
  padding-bottom: 40px;
	overflow-y: scroll;
}
.shopping-list-con {
	width: 100%;
	padding-top: 6px;
}
.pay-type {
	width: 100%;
	height: 45px;
	font-size: 16px;
	color: #333333;
	line-height: 45px;
	text-align: center;
	border-bottom: 1px solid #dfdfdf;
}
.remind {
	width: 100%;
	line-height: 20px;
	padding: 4px 10px;
	font-size: 12px;
}
.make-person {
	width: 98%;
	left: 50%;
	height: 50px;
	line-height: 50px;
	margin-left: -49%;
	border-radius: 8px;
}
</style>
