<template>
  <div>
    <div class="order-item" v-for="(item_obj, idx) in item_arr" :key="idx">
      <div class="order-item-top">
        <div class="order-item-topleft">
          <span>单号: {{item_obj.ordersn}}</span>
        </div>
        <span>支付方式: {{item_obj.payment_code | pay_type}}</span>
      </div>
      <div class="order-item-bottom">
        <span class="order-time">{{item_obj.create_time}}</span>
        <div class="order-bot">
          <div class="order-main-left">
            <div class="main-item">
              <span>名称:</span>
              <span>{{item_obj.name}}</span>
            </div>
            <div class="main-item">
              <span>数量:</span>
              <span>{{item_obj.num}} 升</span>
            </div>
            <div class="main-item">
              <span>类型:</span>
              <span>{{item_obj.type.name}}</span>
            </div>
          </div>
          <div class="order-main-right">
            <span>￥{{item_obj.amount}}</span>
            <span>{{item_obj.pay_status == '1' ? '未支付':'已支付' }} </span>
          </div>
        </div>
      </div>
      <div class="open-card" v-if="item_obj.receipt">
        <div>发票状态</div>
        <span :class="item_obj.receipt == 1 ? 'bill-ready':'bill-end'">{{ item_obj.receipt | now_status}} </span>
      </div>
      <!-- <div class="open-card" v-else>
        <router-link :to="{ path: 'invoice_mag', query: { ordersn: item_obj.id }}">
          <span>去开票</span>
        </router-link>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item_arr: {
      type: Array,
    },
  },
  filters: {
    now_status(val) {
      switch (val) {
        case 1:
          return "待确认"
        case 2:
          return "已开票"
        default:
          break;
      }
    },
    pay_type(val) {
      switch (val) {
        case "balance":
          return "余额支付";
        case "allinpay":
          return "支付宝支付";
        case "unionpay":
          return "银联支付";  
        case "wxpay":
          return "微信支付";
        case "cash":
          return "现金支付";  
        case "card_balance":
          return "油卡支付";
        case "family_balance": 
          return "亲属卡支付";
        case "team_balance":
          return "车队卡支付"      
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-item {
  width: 96%;
  height: auto;
  margin: 10px auto;
  border-radius: 6px;
  padding: 10px 0;
  background: #ffffff;
  > .order-item-top {
    width: 100%;
    height: 30px;
    display: flex;
    padding: 4px 10px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    > .order-item-topleft {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  > .open-card {
    width: 100%;
    padding: 10px 20px 0;
    line-height: 20px;
    text-align: right;
    font-size: 14px;
    display: flex;
    border-top: 1px solid #dfdfdf;
    justify-content: space-between;
    margin-top: 4px;
    align-items: center;
    & a:hover {
      text-decoration: none;
    }
    & span {
      display: block;
      width: 90px;
      height: 30px;
      text-align: center;
      color: #363636;
      line-height: 30px;
      border-radius: 4px;
      border-radius: 3px;
    }
    > .bill-ready {
      color: #ec0101 !important;
      background-color: #dfdfdf;
    }
    > .bill-end {
      color: #363636 !important;
      background-color: #dfdfdf;
    }
  }
  > .order-item-bottom {
    width: 100%;
    height: auto;
    padding: 0 10px;
    > .order-bot {
      width: 100%;
      height: auto;
      display: flex;
      > .order-main-left {
        width: 70%;
        display: flex;
        flex-direction: column;
        > .main-item {
          display: flex;
          font-size: 14px;
          height: 20px;
          align-items: center;
          justify-content: flex-start;
          & span:nth-child(2) {
            margin-left: 4px;
          }
        }
      }
      > .order-main-right {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        justify-content: center;
        & span {
          width: 100%;
          display: inline-block;
          text-align: center;
        }
        & span:nth-child(2) {
          font-size: 14px;
        }

      }
    }
    > .order-time {
      font-size: 14px;
      font-weight: 560;
      color: #000000;
      line-height: 25px;
    }
  }
}
</style>